import axios from 'axios';
import * as Sentry from '@sentry/react';
import getPath from '../assets/routes/paths';
// import getPath from '../assets/routes/paths';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/v3`,
  timeout: 20000,
  headers: { 'Content-Type': 'application/json' },
  crossDomain: true
  // withCredentials: true
});

instance.interceptors.request.use((config) => {
  const language = window.localStorage.getItem('gh.language');
  config.params = { ...config.params, language };
  return config;
});

instance.interceptors.response.use(
  (response) => {
    // console.log('Headers', response.headers);
    return response;
  },
  (error) => {
    if (error.response?.status === 401 && !error.request?.responseURL.includes('/auth/token')) {
      const language = window.localStorage.getItem('gh.language');
      window.location = getPath(language, 'Login');
      // window.location = `${window.location.origin}/${language}/my-golf/login`;
    }
    return Promise.reject(error);
  }
);

const request = async (method, resource, body, token = null, fullErrorMessage = false) => {
  // console.log('request', method, resource, body, token);

  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await instance.request({
      method,
      // url: process.env.NODE_ENV ? `${resource}?isDev=1` : resource,
      url: resource,
      data: body
    });

    return Promise.resolve({ response: response.data });
  } catch (err) {
    // console.log('ERR: ', err);
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log('Data', err.response.data);
      // console.log('Status:', err.response.status);
    } else if (err.request) {
      // console.log('Request: ', err.request);
      return Promise.resolve({ error: 'Network error!' });
    } else {
      Sentry.captureException(err);
      // console.log('Error Api', err.message);
      return Promise.resolve({ error: 'Unknown error' });
    }

    if (fullErrorMessage) {
      return Promise.resolve({ error: err.response?.data });
    }
    const error = err.response ? err.response.data.message : err.message;
    return Promise.resolve({ error: error || 'Unknown error' });
  }
};

export const get = (url, token = null, fullErrorMessage = false) => request('get', url, null, token, fullErrorMessage);
export const post = (url, body, token = null) => request('post', url, body, token);
export const put = (url, body, token = null) => request('put', url, body, token);
export const del = (url, body, token = null) => request('delete', url, body, token);

export const createPaymentIntent = (cart, user) => post('/payments', { cart, user });
export const createInvoice = (cart, user) => post('/payments', { cart, user });
export const createSwishPayment = (cart, user) => post('/payments', { cart, user });
export const createMobilePayment = (cart, user) => post('/payments', { cart, user });
export const validatePayment = (orderId) => get(`/payments/${orderId}/swish/check`);
export const createPaypalOrder = (cart, user) => post('/payments', { cart, user });
export const capturePaypalOrder = (orderId, paymentId) => post(`/payments/${orderId}/paypal/${paymentId}/capture`);
export const createBonusCodePayment = (cart, user) => post('/payments', { cart, user });
export const getPayment = (orderId) => get(`/payments/${orderId}`);

export const getProducts = (currency = null, products = null) =>
  get(`/products?x${currency ? `&currency=${currency}` : ''}${products ? `&products=${products}` : ''}`);
export const validateDiscountCode = (code, products) => post(`/products/discounts/${code}`, { products });

export const getCities = () => get('/cities');

export const getGolfCourses = (participating) => get(`/courses${participating ? '?participating=1' : ''}`);
export const getReviews = (courseId) => get(`/courses/${courseId ? `${courseId}/` : ''}reviews`);
export const getCourseDescription = (courseId) => get(`/courses/${courseId}/description`);
export const getDistrictDescription = (districtId) => get(`/districts/${districtId}/description`);

export const getCountries = () => get('/countries');
export const getCity = (postCode, country) => get(`/users/addresses?postCode=${postCode}&country=${country}`);

export const getUser = (ssn) => get(`/users/${ssn}`);
export const getUserAndValidate = (ssn, cardNr) => get(`/users/${ssn}?valid-for-card-activation=1&cardNr=${cardNr}`, null, true);

export const getCard = (cardNr) => get(`/cards/${cardNr}`);
export const validForActivation = (cardNr, data = []) => post(`/cards/${cardNr}/validate`, data);
export const activateCard = (cardNr, userData) => post(`/cards/${cardNr}/activate`, userData);

export const validGiftCode = (code) => get(`/footjoycodes/${code}/validate`);
export const validatePlusCode = (plusCode) => post(`/cards/validate-plus`, { plusCode });

export const activatePlusCode = (cardNr, plusCode) => post(`/cards/${cardNr}/activate-plus`, { plusCode });

export const getPlusTimes = (courseId) => get(`/plus-times${courseId ? `?course-id=${courseId}` : ''}`);
export const getTrophyContests = () => get('/trophy/contests');
export const getTrophyPartners = (type) => get(`/trophy/sponsors?contest-type=${type}`);
export const getContestOrderOfMerit = () => get('/trophy/oom');

export const getTransactions = (token) => get('/payments/me', token);
export const sendReceipt = (data) => post('/payments/receipt/send', data);

export const validateSurvey = (visitId, hash, language) => get(`/surveys/validate?visitId=${visitId}&hash=${hash}&language=${language}`);

export const saveSurvey = (data) => post('/surveys', data);

export const getRetailers = () => get('/retailers');
export const getFaqs = (recurring = 0) => get(`/faqs?recurring=${recurring}`);
export const getInstaFeed = () => get('/insta');
export const getNews = (limit = 5, offset = 0) => get(`/news?limit=${limit}&offset=${offset}`);
export const getNewsPost = (date, link) => get(`/news?date=${date}&link=${link}`);
export const getFirstPagePost = () => get(`/news?firstPage`);
export const getOffers = (type, token) => get(`/offers${type === 'protected' ? '/protected' : ''}`, token);
export const getSeasonOpenings = () => get('/courses/season-opening');

export const getLatestWebVersion = () => get(`/web/version`);
export const getStatistics = (token) => get(`/statistics/me`, token);

export const signUpToNewsletter = (email) => post(`/newsletter/sign-up`, { email });

export const sendFootjoyData = (data) => post(`/trophy/footjoy`, data);

export const sendGolfClubData = (data) => post(`/golfclub/send`, data);
export const validateCampaignCode = (campaign, code) => get(`/campaign?campaign=${campaign}&code=${code}`);
export const validateOrderId = (orderId, hash, number) => get(`/campaign/order?id=${orderId}&hash=${hash}&number=${number}`);

export const saveGiftSelection = (data) => post(`/campaign`, data);
export const saveGiftSelectionOrder = (data) => post(`/campaign/order`, data);

export const calendarSignUp = (data) => post(`/sign-up?for=advent-calendar-21`, data);

export const getCurrentCampaign = () => get('/campaign/current');
export const getCampaignGifts = (campaign) => get(`/products/gifts?campaign=${campaign}`);

export const claimEasterNumber = (data) => post('/contests/easter', data);
export const claimEasterNumberWithUser = (data) => put('/contests/easter', data);

// new OnTee API

export const getOrdersOnTee = (ghCardNr, active, limit, offset, token) =>
  get(`/ontee-orders?cardNr=${ghCardNr}&active=${active}&limit=${limit}&offset=${offset}`, token);

export const cancelBookingOnTee = (idBooking, ghCardNr, idClub, startTime, userId, token) =>
  del(`/ontee-booking/${idBooking}?cardNr=${ghCardNr}&idClub=${idClub}&visitDateTime=${startTime}&userId=${userId}`, token);

export const validateReferral = (email, userId) => get(`/referral/validate/user?email=${email}&userId=${userId}`);

export const validateStrawberryMembership = ({ memberId, emailMobile }) =>
  get(`/users/strawberry/validate?memberId=${memberId}&emailMobile=${emailMobile}`);

export const addLead = (email, lead) => post(`/leads`, { email, lead });
export const addLeadFromSource = (email, source, type) => post(`/leads/source`, { email, source, type });
