import { lazy } from 'react';
import { detectLanguage } from '../../services/helpers';
// import Index from '../../views/Index';
// import HowItWorks from '../../views/HowItWorks';
// import GolfCourses from '../../views/GolfCourses';
// import GolfCourse from '../../views/GolfCourse';

// import Activate from '../../views/Activate';
// import Webshop from '../../views/Webshop';
// import GolfClub from '../../views/GolfClub';
// import NewsPost from '../../views/NewsPost';

const Index = lazy(() => import('../../views/Index'));
const HowItWorks = lazy(() => import('../../views/HowItWorks'));
const GolfCourses = lazy(() => import('../../views/GolfCourses'));
const CountryDistrict = lazy(() => import('../../views/CountryDistrict'));
const GolfCourse = lazy(() => import('../../views/GolfCourse'));
const Map = lazy(() => import('../../views/Map'));
const Activate = lazy(() => import('../../views/Activate'));
const ActivateAdidas = lazy(() => import('../../views/Activate'));
const Webshop = lazy(() => import('../../views/Webshop'));
const GolfClub = lazy(() => import('../../views/GolfClub'));
const NewsPost = lazy(() => import('../../views/NewsPost'));
const ActivatePlus = lazy(() => import('../../views/ActivatePlus'));
const Account = lazy(() => import('../../views/Account'));
const Login = lazy(() => import('../../views/Login'));
const About = lazy(() => import('../../views/About'));
const Contact = lazy(() => import('../../views/Contact'));
const Recommend = lazy(() => import('../../views/Recommend'));
const Press = lazy(() => import('../../views/Press'));
const Faq = lazy(() => import('../../views/Faq'));
const Terms = lazy(() => import('../../views/Terms'));
const Privacy = lazy(() => import('../../views/Privacy'));
const Cookies = lazy(() => import('../../views/Cookies'));
const News = lazy(() => import('../../views/News'));
const Retailers = lazy(() => import('../../views/Retailers'));
const Survey = lazy(() => import('../../views/Survey'));
const Plus = lazy(() => import('../../views/Plus'));
const Compete = lazy(() => import('../../views/Compete'));
const Trophy = lazy(() => import('../../views/Trophy'));
const Upc = lazy(() => import('../../views/Upc'));
const Final = lazy(() => import('../../views/Final'));
const IndoorOpen = lazy(() => import('../../views/IndoorOpen'));
const MinHusbilOpen = lazy(() => import('../../views/MinHusbilOpen'));
const TrophyFootjoy = lazy(() => import('../../views/TrophyFootjoy'));
const Contests = lazy(() => import('../../views/Contests'));
const TrophyPartners = lazy(() => import('../../views/TrophyPartners'));
const UpcPartners = lazy(() => import('../../views/UpcPartners'));
const TrophyBroHof = lazy(() => import('../../views/TrophyFinal'));
const ContestOrderOfMerit = lazy(() => import('../../views/ContestOrderOfMerit'));
const SeasonOpening = lazy(() => import('../../views/SeasonOpening'));
const SASEuroBonus = lazy(() => import('../../views/SASEuroBonus'));
const Redirect = lazy(() => import('../../views/Redirect'));
const Quiz = lazy(() => import('../../views/Quiz'));
const QuizWhoSaidIt = lazy(() => import('../../views/QuizWhoSaidIt'));
const PublicDeals = lazy(() => import('../../views/PublicDeals'));
const Campaign = lazy(() => import('../../views/Campaign'));
const Campaignp = lazy(() => import('../../views/Campaignp'));
const AdventCalendar = lazy(() => import('../../views/AdventCalendar'));
const App = lazy(() => import('../../views/App'));
const WebForm = lazy(() => import('../../views/WebForm'));
const FreeRound = lazy(() => import('../../views/FreeRound'));
const Referral = lazy(() => import('../../views/Referral'));
const CustomerClub = lazy(() => import('../../views/CustomerClub'));
const Events = lazy(() => import('../../views/Events/Events'));
const OrderOfMerit = lazy(() => import('../../views/Events/OrderOfMerit'));
const Calendar = lazy(() => import('../../views/Events/Calendar'));
const Competitions = lazy(() => import('../../views/Events/Competitions'));
const CompetitionGolfamoreTrophy = lazy(() => import('../../views/Events/Competitions/CompetitionGolfamoreTrophy'));
const CompetitionUnderParChallenge = lazy(() => import('../../views/Events/Competitions/CompetitionUnderParChallenge'));
const CompetitionFootJoyMasters = lazy(() => import('../../views/Events/Competitions/CompetitionFootJoyMasters'));
const CompetitionIndoorOpen = lazy(() => import('../../views/Events/Competitions/CompetitionIndoorOpen'));
const Tours = lazy(() => import('../../views/Events/Tours'));
const TourBroHof = lazy(() => import('../../views/Events/Tours/TourBroHof'));
const TourTheNational = lazy(() => import('../../views/Events/Tours/TourTheNational'));
const TourVallda = lazy(() => import('../../views/Events/Tours/TourVallda'));
const TourOsteraker = lazy(() => import('../../views/Events/Tours/TourOsteraker'));
const TourHalmstad = lazy(() => import('../../views/Events/Tours/TourHalmstad'));
const Extreme = lazy(() => import('../../views/Events/Extreme'));

const Bunkered = lazy(() => import('../../views/Bunkered'));
const DanskGolfShow = lazy(() => import('../../views/DanskGolfShow'));
const Leads = lazy(() => import('../../views/Leads'));

// Only germany
const AGB = lazy(() => import('../../views/germany/Agb'));
const Widerrufsbelehrung = lazy(() => import('../../views/germany/Widerrufsbelehrung'));
const Impressum = lazy(() => import('../../views/germany/Impressum'));
const Datenschutzerklarung = lazy(() => import('../../views/germany/Datenschutzerklarung'));

// Keep synced with ./paths.js since we need this on two places to prevent circular dependencies.
const routesSetup = [
  {
    // name: 'Index',
    component: Index,
    paths: {
      sv: '',
      dk: '',
      no: '',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    // name: 'Activate',
    component: Activate,
    paths: {
      sv: 'aktivera-kort',
      dk: 'aktiver-kort',
      no: 'aktivere-kort',
      fi: 'aktivoi-kortti',
      en: 'activate-card',
      de: 'karte-aktivieren',
      cs: 'activate-card'
    }
  },
  {
    // name: 'Activate',
    component: ActivateAdidas,
    paths: {
      sv: 'aktivera-kort/adidas',
      dk: 'aktiver-kort/adidas',
      no: 'aktivere-kort/adidas',
      en: 'activate-card/adidas',
      de: 'activate-card/adidas'
    }
  },
  {
    // name: 'ActivatePlus',
    component: ActivatePlus,
    paths: {
      sv: 'aktivera-kort/plus',
      dk: 'aktiver-kort/plus',
      no: 'aktivere-kort/plus',
      fi: 'aktivoi-kortti/plus',
      en: 'activate-card/plus',
      de: 'activate-card/plus',
      cs: 'activate-card/plus'
    }
  },
  {
    // name: 'Webshop',
    component: Webshop,
    paths: {
      sv: 'webshop',
      dk: 'webshop',
      no: 'webshop',
      fi: 'verkkokauppa',
      en: 'webshop',
      de: 'webshop',
      cs: 'objednat'
    }
  },
  {
    component: Map,
    paths: {
      sv: 'karta',
      dk: 'kort',
      no: 'kart',
      fi: 'kartta',
      en: 'map',
      de: 'karte',
      cs: 'mapa'
    }
  },
  {
    // name: 'GolfCourses',
    component: GolfCourses,
    paths: {
      sv: 'golfbanor',
      dk: 'golfbaner',
      no: 'golfbaner',
      fi: 'golfkentät',
      en: 'golf-courses',
      de: 'golf-courses',
      cs: 'golf-courses'
    }
  },
  {
    component: CountryDistrict,
    paths: {
      sv: 'golfbanor/:country',
      dk: 'golfbaner/:country',
      no: 'golfbaner/:country',
      fi: 'golfkentät/:country',
      en: 'golf-courses/:country',
      de: 'golf-courses/:country',
      cs: 'golf-courses/:country'
    }
  },
  {
    component: CountryDistrict,
    paths: {
      sv: 'golfbanor/:country/:district',
      dk: 'golfbaner/:country/:district',
      no: 'golfbaner/:country/:district',
      fi: 'golfkentät/:country/:district',
      en: 'golf-courses/:country/:district',
      de: 'golf-courses/:country/:district',
      cs: 'golf-courses/:country/:district'
    }
  },
  {
    // name: 'GolfCourse',
    component: GolfCourse,
    paths: {
      sv: 'golfbanor/:country/:district/:course',
      dk: 'golfbaner/:country/:district/:course',
      no: 'golfbaner/:country/:district/:course',
      fi: 'golfkentät/:country/:district/:course',
      en: 'golf-courses/:country/:district/:course',
      de: 'golf-courses/:country/:district/:course',
      cs: 'golf-courses/:country/:district/:course'
    }
  },
  {
    // name: 'Trophy',
    component: Compete,
    paths: {
      sv: 'tavla',
      dk: 'konkurrence',
      no: 'konkurranse',
      fi: null,
      en: 'contest',
      de: null,
      cs: null
    }
  },
  {
    // name: 'Trophy',
    component: Trophy,
    paths: {
      sv: 'tavla/trophy',
      dk: 'konkurrence/trophy',
      no: 'konkurranse/trophy',
      fi: null,
      en: 'contest/trophy',
      de: null,
      cs: null
    }
  },
  {
    component: Final,
    paths: {
      sv: 'tavla/final',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Upc,
    paths: {
      sv: 'tavla/upc',
      dk: 'konkurrence/upc',
      no: 'konkurranse/upc',
      fi: null,
      en: 'contest/upc',
      de: null,
      cs: null
    }
  },
  {
    component: IndoorOpen,
    paths: {
      sv: 'tavla/indoor-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: MinHusbilOpen,
    paths: {
      sv: 'tavla/minhusbil-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TrophyFootjoy,
    paths: {
      sv: 'tavla/trophy/footjoy',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Contests,
    paths: {
      sv: 'tavla/program',
      dk: 'konkurrence/program',
      no: 'konkurranse/skjmea',
      fi: null,
      en: 'contest/program',
      de: null,
      cs: null
    }
  },
  {
    // name: 'TrophyPartners',
    component: TrophyPartners,
    paths: {
      sv: 'tavla/trophy/partners',
      dk: 'konkurrence/trophy/partnere',
      no: 'konkurranse/trophy/partners',
      fi: null,
      en: 'contest/trophy/partners',
      de: null,
      cs: null
    }
  },
  {
    component: UpcPartners,
    paths: {
      sv: 'tavla/upc/partners',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    // name: 'TrophyBroHof',
    component: TrophyBroHof,
    paths: {
      sv: 'tavla/trophy/final',
      dk: 'konkurrence/trophy/finale',
      no: 'konkurranse/trophy/finale',
      fi: null,
      en: 'contest/trophy/final',
      de: null,
      cs: null
    }
  },
  {
    component: ContestOrderOfMerit,
    paths: {
      sv: 'tavla/order-of-merit',
      dk: 'konkurrence/order-of-merit',
      no: 'konkurranse/order-of-merit',
      fi: null,
      en: 'contest/order-of-merit',
      de: null,
      cs: null
    }
  },
  {
    // name: 'Plus',
    component: Plus,
    paths: {
      sv: 'plus',
      dk: 'plus',
      no: 'plus',
      fi: 'plus',
      en: 'plus',
      de: 'plus',
      cs: 'plus'
    }
  },
  {
    // name: 'Login',
    component: Login,
    paths: {
      sv: 'min-golf/logga-in',
      dk: 'min-golf/log-ind',
      no: 'min-golf/logg-in',
      fi: 'oma-golf/kirjaudu-sisään',
      en: 'my-golf/login',
      de: 'my-golf/login',
      cs: 'my-golf/login'
    }
  },
  {
    // name: 'Account',
    component: Account,
    paths: {
      sv: 'min-golf/:view',
      dk: 'min-golf/:view',
      no: 'min-golf/:view',
      fi: 'oma-golf/:view',
      en: 'my-golf/:view',
      de: 'my-golf/:view',
      cs: 'my-golf/:view'
    },
    privateRoute: true
  },
  {
    // name: 'Account',
    component: Account,
    paths: {
      sv: 'min-golf',
      dk: 'min-golf',
      no: 'min-golf',
      fi: 'oma-golf',
      en: 'my-golf',
      de: 'my-golf',
      cs: 'my-golf'
    },
    privateRoute: true
  },
  {
    // name: 'News',
    component: News,
    paths: {
      sv: 'nyheter',
      dk: 'nyheder',
      no: 'nyheter',
      fi: 'uutiset',
      en: 'news',
      de: 'neuigkeiten',
      cs: 'news'
    }
  },
  {
    // name: 'News',
    component: NewsPost,
    paths: {
      sv: 'nyheter/:date/:link',
      dk: 'nyheder/:date/:link',
      no: 'nyheter/:date/:link',
      fi: 'uutiset/:date/:link',
      en: 'news/:date/:link',
      de: 'neuigkeiten/:date/:link',
      cs: 'news/:date/:link'
    }
  },
  {
    // name: 'Faq',
    component: Faq,
    paths: {
      sv: 'faq',
      dk: 'faq',
      no: 'faq',
      fi: 'faq',
      en: 'faq',
      de: 'faq',
      cs: 'faq'
    }
  },
  {
    // name: 'About',
    component: About,
    paths: {
      sv: 'om-oss',
      dk: 'om-os',
      no: 'om-oss',
      fi: 'meistä',
      en: 'about',
      de: 'about',
      cs: 'about'
    }
  },
  {
    // name: 'Retailers',
    component: Retailers,
    paths: {
      sv: 'aterforsaljare',
      dk: 'forhandler',
      no: 'forhandler',
      fi: 'jälleenmyyjät',
      en: null,
      de: null,
      cs: null
    }
  },
  {
    // name: 'Contact',
    component: Contact,
    paths: {
      sv: 'kontakt',
      dk: 'kontakt',
      no: 'kontakt',
      fi: 'kontakti',
      en: 'contact',
      de: 'contact',
      cs: 'contact'
    }
  },
  {
    // name: 'Press',
    component: Press,
    paths: {
      sv: 'press',
      dk: 'press',
      no: 'press',
      fi: 'lehdistö',
      en: 'press',
      de: 'press',
      cs: 'press'
    }
  },
  {
    // name: 'Terms',
    component: Terms,
    paths: {
      sv: 'kopvillkor',
      dk: 'vilkar',
      no: 'vilkar',
      fi: 'ehdot',
      en: 'terms',
      de: 'terms',
      cs: 'nakupni'
    }
  },
  {
    // name: 'Privacy',
    component: Privacy,
    paths: {
      sv: 'integritetspolicy',
      dk: 'privatlivspolitik',
      no: 'personvern',
      fi: 'tietosuojakäytäntö',
      en: 'privacy-policy',
      de: 'privacy-policy',
      cs: 'privacy-policy'
    }
  },
  {
    // name: 'Cookies',
    component: Cookies,
    paths: {
      sv: 'cookies',
      dk: 'cookies',
      no: 'cookies',
      fi: 'evästeet',
      en: 'cookies',
      de: 'cookies',
      cs: 'cookies'
    }
  },
  {
    // name: 'Recommend',
    component: Recommend,
    paths: {
      sv: 'rekommendera',
      dk: 'anbefale',
      no: 'anbefale',
      fi: 'suositella',
      en: 'recommend',
      de: null,
      cs: null
    }
  },
  {
    // name: 'GolfClub',
    component: GolfClub,
    paths: {
      sv: 'golfklubb',
      dk: 'golfklub',
      no: 'golfklubb',
      fi: 'Golfklubi',
      en: 'golf-club',
      de: 'golf-club',
      cs: 'golf-club'
    }
  },
  {
    // name: 'Survey',
    component: Survey,
    paths: {
      sv: 'undersokning',
      dk: 'undersogelse',
      no: 'undersokelse',
      fi: 'arviot',
      en: 'survey',
      de: 'survey',
      cs: 'survey'
    }
  },
  {
    // name: 'Survey',
    component: HowItWorks,
    paths: {
      sv: 'sa-fungerar-det',
      dk: 'sådan-fungerer-det',
      no: 'slik-det-fungerer',
      fi: 'niin-se-toimii',
      en: 'how-it-works',
      de: 'so-funktioniert-es',
      cs: 'tak-to-funguje'
    }
  },
  {
    component: Campaign,
    paths: {
      sv: 'kampanj',
      dk: 'kampagne',
      no: 'kampanje',
      fi: 'kampanja',
      en: 'campaign',
      de: 'kampagne',
      cs: ''
    }
  },
  {
    component: Campaignp,
    paths: {
      sv: 'kampanjp',
      dk: 'kampagnep',
      no: 'kampanjep',
      fi: 'kampanjap',
      en: 'campaignp',
      de: 'kampagnep',
      cs: ''
    }
  },
  {
    component: SeasonOpening,
    paths: {
      sv: 'golfsäsongen-2025',
      dk: 'golfsæson-2025',
      no: 'golfsesong-2025',
      fi: null,
      en: 'golf-season-2025',
      de: 'golf-saison-2025',
      cs: null
    }
  },
  {
    component: SASEuroBonus,
    paths: {
      sv: 'partners/sas-eurobonus',
      dk: 'partners/sas-eurobonus',
      no: 'partners/sas-eurobonus',
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: CustomerClub,
    paths: {
      sv: 'kund-klubb',
      dk: 'kunde-klub',
      no: 'kunde-klubb',
      fi: null,
      en: 'customer-club',
      de: 'kunden-club',
      cs: null
    }
  },
  {
    component: Extreme,
    paths: {
      sv: 'events/extreme',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Quiz,
    paths: {
      sv: 'quiz',
      dk: 'quiz',
      no: null,
      fi: null,
      en: null,
      de: 'quiz',
      cs: null
    }
  },
  {
    component: QuizWhoSaidIt,
    paths: {
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: 'quiz-who-said-it',
      de: null,
      cs: null
    }
  },
  {
    component: PublicDeals,
    paths: {
      sv: 'deals',
      dk: 'deals',
      no: 'deals',
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: AdventCalendar,
    paths: {
      sv: 'adventskalender',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: App,
    paths: {
      sv: 'app',
      dk: 'app',
      no: 'app',
      fi: 'app',
      en: 'app',
      de: 'app',
      cs: 'app'
    }
  },
  {
    component: WebForm,
    paths: {
      sv: 'web-form/:id/:golfclub/:hash',
      dk: '',
      no: '',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    component: FreeRound,
    paths: {
      sv: 'bjudrundan',
      dk: '',
      no: '',
      fi: '',
      en: '',
      de: 'proberunde',
      cs: ''
    }
  },
  {
    component: Referral,
    paths: {
      sv: 'referral',
      dk: '',
      no: '',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    component: AGB,
    paths: {
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: 'agb',
      cs: null
    }
  },
  {
    component: Widerrufsbelehrung,
    paths: {
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: 'widerrufsbelehrung',
      cs: null
    }
  },
  {
    component: Impressum,
    paths: {
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: 'impressum',
      cs: null
    }
  },
  {
    component: Datenschutzerklarung,
    paths: {
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: 'datenschutzerklarung',
      cs: null
    }
  },
  // new Golfamore Events
  {
    component: Events,
    paths: {
      sv: 'events',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Competitions,
    paths: {
      sv: 'events/tävlingar',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: CompetitionGolfamoreTrophy,
    paths: {
      sv: 'events/golfamore-trophy',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: CompetitionUnderParChallenge,
    paths: {
      sv: 'events/under-par-challenge',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: CompetitionFootJoyMasters,
    paths: {
      sv: 'events/footjoy-masters',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: CompetitionIndoorOpen,
    paths: {
      sv: 'events/indoor-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Calendar,
    paths: {
      sv: 'events/tavlingskalendern',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: OrderOfMerit,
    paths: {
      sv: 'events/order-of-merit',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Tours,
    paths: {
      sv: 'events/on-tour',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TourBroHof,
    paths: {
      // sv: 'events/on-tour-bro-hof',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TourTheNational,
    paths: {
      // sv: 'events/on-tour-the-national',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TourVallda,
    paths: {
      // sv: 'events/on-tour-vallda',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TourOsteraker,
    paths: {
      // sv: 'events/on-tour-osteraker',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: TourHalmstad,
    paths: {
      // sv: 'events/on-tour-halmstad',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Bunkered,
    paths: {
      // sv: 'events/on-tour-halmstad',
      sv: null,
      dk: null,
      no: null,
      fi: null,
      en: 'bunkered',
      de: null,
      cs: null
    }
  },
  {
    component: DanskGolfShow,
    paths: {
      sv: null,
      dk: 'danskgolfshow',
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    component: Leads,
    paths: {
      sv: 'i/:influencer',
      dk: 'i/:influencer',
      no: 'i/:influencer',
      fi: null,
      en: 'i/:influencer',
      de: 'i/:influencer',
      cs: null
    }
  },
  {
    // name: 'Redirect',
    component: Redirect,
    paths: {
      sv: 'r',
      dk: 'r',
      no: 'r',
      fi: 'r',
      en: 'r',
      de: 'r',
      cs: ''
    }
  }
];

const languageCode = detectLanguage();

const routes = routesSetup.map((r) => ({
  path: `/${languageCode}/${r.paths[languageCode]}`,
  component: r.component,
  privateRoute: r.privateRoute
}));

routes.push({ path: '/r', name: 'Redirect', component: Redirect });

export default routes;
